import React from "react";
import c from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import cart$ from "store/cart";
import user$ from "store/user";
import { Cart } from "types/cart";
import { User$ } from "types/user";
import { logout } from "store/auth";
import { useObservable } from "helpers/observable";

export const socials: { title: string; icon: string; href: string }[] = [
    {
        title: "Instagram",
        icon: "icon-instagram",
        href: "https://www.instagram.com/Ticlixcom/",
    },
    {
        title: "Youtube",
        icon: "icon-youtube",
        href: "https://youtube.com/channel/UCChNcg3zM8NMyxDayAwt2tw",
    },
    {
        title: "Twitter",
        icon: "icon-x",
        href: "http://twitter.com/Ticlixcom",
    },
    {
        title: "Telegram",
        icon: "icon-telegram",
        href: "https://t.me/+971585791214",
    },
    {
        title: "Facebook",
        icon: "icon-facebook",
        href: "https://www.facebook.com/Ticlix",
    },
];

export const navLinks = {
    "common:congrats.home": "/",
    "common:congrats.todayconcert": "/concert/today",
    "common:concert.~": "/concert",
    // 'Artists': '/artist',
    // 'Salons': '/salon'
};

interface Props {}
function AppHeader({}: Props): JSX.Element {
    const { t } = useTranslation();
    const cart = useObservable<Cart>(cart$);
    const user = useObservable<User$>(user$);

    const router = useRouter();
    const isActive = (href: string): string =>
        router.asPath == href ? "active" : "";

    function openSideMenu(): void {
        document.getElementsByTagName("aside")[0]?.focus();
    }

    return (
        <header>
            <div className="container">
                <div className="row flex-nowrap gx-3 nav-support d-none d-lg-flex">
                    {/* Support Link */}
                    <div className="col-auto px-3 me-auto">
                        <a className="text-nowrap" href="tel:+97144522031">
                            {t("common:support")}:
                            <span className="d-inline-block ms-2" dir="ltr">
                                +97144522031
                            </span>
                        </a>
                    </div>
                    {/* Social Links */}
                    {socials.map(({ title, icon, href }) => (
                        <div className="col-auto px-3" key={title}>
                            <a
                                className="social-item"
                                href={href}
                                title={title}
                            >
                                <span className={`icon ${icon}`}></span>
                            </a>
                        </div>
                    ))}
                    <div className="col-auto">
                        <a
                            href="https://api.whatsapp.com/send?phone=971585791214"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src="/static/images/icons/whatsapp.svg"
                                alt="whatsapp logo"
                            />
                        </a>
                    </div>
                    {/* --- */}
                </div>
                {/* Navbar */}
                <nav className="row flex-nowrap align-items-center py-5">
                    {/* Sidebar Toggle */}
                    <div
                        className="col-auto pointer d-block d-lg-none"
                        onClick={openSideMenu}
                    >
                        <span className="icon icon-menu"></span>
                    </div>
                    {/* Nav Brand */}
                    <div className="col-auto nav-brand d-none d-lg-block">
                        <Link href="/">
                            <a>
                                <img
                                    src="/static/images/ticlix-origin.svg"
                                    alt="ticlix-logo"
                                />
                            </a>
                        </Link>
                    </div>
                    {/* Nav Links */}
                    {Object.entries(navLinks).map(([title, href]) => (
                        <div className="col-auto d-none d-lg-block" key={title}>
                            <Link href={href}>
                                <a
                                    className={`nav-link d-block py-3 ${isActive(
                                        href
                                    )}`}
                                >
                                    {t(title)}
                                </a>
                            </Link>
                        </div>
                    ))}
                    <div className="col d-none d-lg-block"></div>
                    <div className="col d-lg-none"></div>
                    <div
                        className={c(
                            "col-auto mx-auto d-lg-none",
                            user ? "flex-grow-3" : "flex-grow-1"
                        )}
                    >
                        <Link href="/">
                            <a>
                                <img
                                    src="/static/images/ticlix-origin.svg"
                                    alt="ticlix-logo"
                                />
                            </a>
                        </Link>
                    </div>
                    <div className="col d-lg-none"></div>
                    {user ? (
                        <>
                            {/* Nav Icons */}
                            <div className="col-auto">
                                <Link href="/cart">
                                    <a className="nav-icon">
                                        <span
                                            className="icon icon-ticket"
                                            icon-badge={
                                                cart?.items.length || null
                                            }
                                        ></span>
                                    </a>
                                </Link>
                            </div>
                            {/* User Profile & Dropdown Menu */}
                            <div
                                className="col-auto dropdown px-0 mx-4 d-none d-lg-block"
                                tabIndex={0}
                            >
                                <div
                                    className="profile-photo"
                                    style={{
                                        backgroundImage: `url(${user.avatar})`,
                                    }}
                                ></div>
                                <div className="dropdown-list">
                                    {/* Profile Info */}
                                    <div className="dropdown-item p-4">
                                        <b className="text-primary">
                                            {user.first_name} {user.last_name}
                                        </b>
                                        <div className="text-caption mt-1">
                                            <span
                                                className="d-inline-block"
                                                dir="ltr"
                                            >
                                                {user?.email || user?.phone}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="dropdown-separator"></div>
                                    {/* --- */}
                                    <div className="dropdown-item p-4">
                                        <Link href="/profile">
                                            <a className="dropdown-nav">
                                                <span className="icon icon-profile me-4"></span>
                                                {t("common:user.profile")}
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="dropdown-item p-4">
                                        <Link href="/profile/ticket/times">
                                            <a className="dropdown-nav">
                                                <span className="icon icon-ticket me-4"></span>
                                                {t("common:user.ticket")}
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="dropdown-item p-4">
                                        <Link href="/profile/invoice">
                                            <a className="dropdown-nav">
                                                <span className="icon icon-wallet me-4"></span>
                                                {t("common:user.invoice")}
                                            </a>
                                        </Link>
                                    </div>
                                    {/* <div className="dropdown-item p-4">
                  <a className="dropdown-nav">
                    <span className="icon icon-heart me-4"></span>
                    My Favorites
                  </a>
                </div> */}
                                    {/* <div className="dropdown-item p-4">
                  <a className="dropdown-nav">
                    <span className="icon icon-chat me-4"></span>
                    My Comments and Rates
                  </a>
                </div> */}
                                    {/* <div className="dropdown-item p-4">
                  <a className="dropdown-nav">
                    <span className="icon icon-video me-4"></span>
                    My Videos
                  </a>
                </div> */}
                                    <div className="dropdown-deparator"></div>
                                    <div className="dropdown-item p-4">
                                        <Link href="/profile/password">
                                            <a className="dropdown-nav">
                                                <span className="icon icon-password me-4"></span>
                                                {t("common:user.password")}
                                            </a>
                                        </Link>
                                        {/* <div className="last-change ps-4 ms-4">{t("common:user.lastchange")}: Jan13,2018</div> */}
                                    </div>
                                    {/* <div className="dropdown-item p-4">
                  <a className="dropdown-nav">
                    <span className="icon icon-lock me-4"></span>
                    OTP Setting
                  </a>
                </div> */}
                                    <div className="dropdown-separator"></div>
                                    {/* Logout */}
                                    <div className="dropdown-item p-4">
                                        <span
                                            className="dropdown-nav text-primary pointer"
                                            onClick={logout}
                                        >
                                            <span className="icon icon-logout me-4"></span>
                                            {t("auth.logout")}
                                        </span>
                                    </div>
                                    {/* --- */}
                                </div>
                            </div>
                            {/* --- */}
                        </>
                    ) : (
                        <>
                            {/* Login */}
                            <div className="col-auto d-none d-lg-block">
                                <Link href="/auth/login">
                                    <a className="text-primary">
                                        {t("auth.login")}
                                    </a>
                                </Link>
                            </div>
                            {/* Register */}
                            <div className="col-auto d-none d-lg-block">
                                <Link href="/auth/register/phone">
                                    <a className="btn btn-primary">
                                        {t("auth.register")}
                                    </a>
                                </Link>
                            </div>
                            {/* --- */}
                        </>
                    )}
                </nav>
                {/* --- */}
            </div>
        </header>
    );
}

export default React.memo(AppHeader);
