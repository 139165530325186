import React from 'react';
import Link from 'next/link';

interface Props { }
export function TabMenu({ }: Props): JSX.Element {
  return (
    <div className="d-md-none tabmenu">
      <div className="links d-flex justify-content-around align-items-center">
        <Link href='/'><a className="icon icon-home p-4"></a></Link>
        <Link href='/concert/today'><a className="icon icon-today-events p-4"></a></Link>
        <Link href='/concert'><a className="icon icon-concert p-4 concert"></a></Link>
        <Link href='/profile/ticket/times'><a className="icon icon-ticket p-4"></a></Link>
        <Link href='/profile'><a className="icon icon-profile p-4"></a></Link>
      </div>
    </div>
  )
}

export default React.memo(TabMenu);
