import c from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useTranslation } from "next-i18next";

import cart$ from "store/cart";
import user$ from "store/user";
import { Cart } from "types/cart";
import { User$ } from "types/user";
import { logout } from "store/auth";
import { socials } from "components/layout/header";
import { useObservable } from "helpers/observable";

export const navLinks = {
    "common:congrats.home": "/",
    "common:congrats.todayconcert": "/concert/today",
    "common:concert.~": "/concert",
    // 'Artists': '/artist',
    // 'Salons': '/salon',
    // 'About Ticlix': '/aboutup',
    // 'Contact Customer Services': '/support'
};

interface Props {}
function AppSidebar({}: Props): JSX.Element {
    const { t } = useTranslation();
    const router = useRouter();

    const cart = useObservable<Cart>(cart$);
    const user = useObservable<User$>(user$);
    const [profileMenu, setProfileMenu] = useState<boolean>(false);

    const isActive = (href: string): string =>
        router.asPath == href ? "active" : "";

    return (
        <aside
            className="d-block d-lg-none aside-menu"
            tabIndex={0}
            onBlur={() => setTimeout(() => setProfileMenu(false), 500)}
        >
            <nav>
                {/* Nav Brand */}
                <div className="d-flex justify-content-between nav-brand">
                    <Link href="/">
                        <a>
                            <img
                                src="/static/images/ticlix-origin.svg"
                                alt="ticlix-logo"
                            />
                        </a>
                    </Link>
                    <span
                        className="icon icon-close close"
                        tabIndex={-1}
                    ></span>
                </div>
                {user ? (
                    <>
                        {/* User Profile */}
                        <div className="d-flex align-items-center user-profile">
                            <img src={user.avatar} alt="profile-photo" />
                            <div className="ps-4">
                                <b className="text-primary">
                                    {user.first_name} {user.last_name}
                                </b>
                                <div className="text-caption mt-1">
                                    <span className="d-inline-block" dir="ltr">
                                        {user?.email || user?.phone}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* Nav Icons */}
                        <div className="d-flex justify-content-between py-4">
                            {/* <a className="nav-icon">
              <span className="icon icon-discount"></span>
            </a>
            <a className="nav-icon">
              <span className="icon icon-notification"></span>
            </a>
            <a className="nav-icon">
              <span className="icon icon-Message"></span>
            </a> */}
                            <Link href="/cart">
                                <a className="nav-icon">
                                    <span
                                        className="icon icon-ticket"
                                        icon-badge={cart?.items.length || null}
                                    ></span>
                                </a>
                            </Link>
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-between
            text-primary pt-4 pe-3"
                            onClick={() => setProfileMenu(true)}
                        >
                            <span>{t("common:dashboard")}</span>
                            <span className="icon icon-right-chevron"></span>
                        </div>
                        {/* --- */}
                    </>
                ) : (
                    <>
                        {/* Register */}
                        <Link href="/auth/register/phone">
                            <a className="btn btn-primary w-100 mb-3">
                                {t("auth.register")}
                            </a>
                        </Link>
                        {/* Login */}
                        <Link href="/auth/login">
                            <a className="btn btn-secondary w-100 mb-3">
                                {t("auth.login")}
                            </a>
                        </Link>
                        {/* --- */}
                    </>
                )}
                <div className="separator my-4"></div>
                {/* Nav Links */}
                {Object.entries(navLinks).map(([title, href]) => (
                    <div className="nav-link py-3" key={title}>
                        <Link href={href}>
                            <a>{t(title)}</a>
                        </Link>
                    </div>
                ))}
                <div className="separator my-4"></div>
                {/* Logout */}
                {user && (
                    <>
                        <div className="nav-link py-3 mb-4">
                            <span
                                className="text-primary pointer"
                                onClick={logout}
                            >
                                <span className="icon icon-logout"></span>
                                {t("auth.logout")}
                            </span>
                        </div>
                    </>
                )}
                {/* Support Link */}
                <div className="support-link py-4">
                    <a href="tel:+9714522031">
                        {t("common:support")}:
                        <span className="d-inline-block ms-2" dir="ltr">
                            (04) 452 2031
                        </span>
                    </a>
                </div>
                {/* Social Links */}
                <div className="row g-0 justify-content-evenly">
                    {socials.map(({ title, icon, href }) => (
                        <div className="col-auto text-center" key={title}>
                            <a
                                className="social-item"
                                href={href}
                                title={title}
                            >
                                <span className={`icon ${icon}`}></span>
                            </a>
                        </div>
                    ))}
                    <div className="col-auto">
                        <a
                            href="https://api.whatsapp.com/send?phone=971585791214"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src="/static/images/icons/whatsapp-blue.svg"
                                alt="whatsapp logo"
                                width={20}
                            />
                        </a>
                    </div>
                </div>
                {/* Profile Menu */}
                <div className={c(`dashboardMenu`, { slideIn: profileMenu })}>
                    <div className=" pb-5">
                        <div
                            className="slideBack text-left"
                            onClick={() => setProfileMenu(false)}
                        >
                            <span className="icon icon-right-chevron"></span>
                        </div>
                    </div>
                    <div className="pb-5">
                        <Link href="/profile">
                            <a className={isActive("/profile")}>
                                <span className="icon icon-profile pe-4"></span>
                                <span className="ps-3">
                                    {t("common:user.profile")}
                                </span>
                            </a>
                        </Link>
                    </div>
                    <div className="pb-5">
                        <Link href="/profile/ticket/times">
                            <a className={isActive("/profile/ticket/times")}>
                                <span className="icon icon-ticket pe-4"></span>
                                <span className="ps-3">
                                    {t("common:user.ticket")}
                                </span>
                            </a>
                        </Link>
                    </div>
                    <div className="pb-5">
                        <Link href="/profile/invoice">
                            <a className={isActive("/profile/invoice")}>
                                <span className="icon icon-wallet pe-4"></span>
                                <span className="ps-3">
                                    {t("common:user.invoice")}
                                </span>
                            </a>
                        </Link>
                    </div>
                    <div className="separator my-4"></div>
                    <div className="pb-5">
                        <Link href="/profile/password">
                            <a className={isActive("/profile/password")}>
                                <span className="icon icon-password pe-4"></span>
                                <span className="ps-3">
                                    {t("common:user.password")}{" "}
                                </span>
                                {/* <span className="d-block lastchange ">
                  {t("common:user.lastchange")}:
                  <span className="date"> Jan13,2018</span>
                </span> */}
                            </a>
                        </Link>
                    </div>
                </div>
                {/* --- */}
            </nav>
        </aside>
    );
}

export default React.memo(AppSidebar);
