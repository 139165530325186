import { useEffect, useState } from "react";
import { Observable, Subscription } from "rxjs";

export function useObservable<T>(subject: Observable<T>): T {
  const [state, setState] = useState<T>();

  useEffect(() => {
    const observer: Subscription = subject.subscribe(setState);
    return () => observer.unsubscribe();
  }, [subject]);

  return state as T;
};
