import React from "react";
import Link from "next/link";
import moment from "jalali-moment";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { socials } from "components/layout/header";

// const helpfulLinks = [
//   'Helpful Links',
//   'My Account',
//   'Ticket Your Event',
//   'Refunds and Exchanges',
//   'Get Help',
//   'Sell',
//   'Gift cards',
//   'N.Y. Registered Brokers',
//   'Do Not Sell My Information'
// ];

// const aboutUs = [
//   'About Us',
//   'Who we are',
//   'Ticketmaster Blog',
//   'Ticketing 101',
//   'Privacy Policy',
//   'Work With Us',
//   'Innovation'
// ];

// const partners = [
//   'Friends & Partners',
//   'American Express',
//   'Allianz',
//   'AWS'
// ];

const helpfulLinks = {
  "footer.helpful": "",
  "common:footer.myaccount": "/profile",
  "common:policy": "/terms",
};

const aboutUs = {
  "footer.about": "",
  "common:footer.about": "/about-us",
  "common:contact": "/contact-us",
};

interface Props {}
function AppFooter({}: Props): JSX.Element {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 order-2">
            <div className="row">
              <div className="col-lg-6 col-auto order-2 mb-5">
                <FooterNav list={helpfulLinks} />
              </div>
              <div className="col-lg-6 col-auto order-3 mb-5 me-auto">
                <FooterNav list={aboutUs} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 order-4 mb-5 d-flex flex-column justify-content-between">
            <ul className="footer-nav"></ul>
            {/* Social Links */}
            <div className="d-none d-lg-flex justify-content-center">
              {socials.map(({ title, icon, href }) => (
                <a
                  className="social-item mx-4"
                  href={href}
                  title={title}
                  key={title}
                >
                  <span className={`icon ${icon}`}></span>
                </a>
              ))}
            </div>
            {/* --- */}
          </div>
          <div className="col-lg-4 order-1 order-lg-last mb-5">
            <img
              className="w-100 d-none d-lg-block"
              alt="emoji-persons"
              src="/static/images/emoji-persons.svg"
            />
            {/* --- */}
          </div>
          <div className="col-lg mb-5 d-block d-lg-none order-last">
            {/* Emoji Persons */}
            <img
              className="w-100"
              alt="emoji-persons"
              src="/static/images/emoji-persons.svg"
            />
            {/* --- */}
          </div>
        </div>
        <div className="copyright">
          Copyright &copy; {new Date().getFullYear()} Ticlix
        </div>
      </div>
    </footer>
  );
}

interface ChildProps {
  list: { [key: string]: string };
}
function FooterNav({ list }: ChildProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <ul className="footer-nav">
      {Object.entries(list).map(([title, href]) => (
        <li key={title}>
          {!href ? (
            t(title)
          ) : (
            <Link href={href}>
              <a>{t(title)}</a>
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
}

export default React.memo(AppFooter);
