import React, { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';

import AppHeader from 'components/layout/header';
import AppSidebar from 'components/layout/sidebar';
import AppFooter from 'components/layout/footer';
import TabMenu from 'components/tab-menu';

type Child = string | ReactElement;
interface Props { children?: any; };
export function AppLayout({ children }: Props): JSX.Element {
  const { t } = useTranslation();

  return (<>
    <AppHeader />
    <AppSidebar />
    {children}
    <AppFooter />
    <TabMenu />
  </>)
};

export default React.memo(AppLayout);
